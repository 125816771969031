<template>
  <div class="system-page page">
    <loading :active.sync="isLoading" loader="dots"></loading>
    <Alert />

    <div class="card search-card">
      <div class="card-body">
        <div class="form-row">
          <div class="col-sm-3 col-md-2">
            <div class="form-group">
              <label>角色名稱</label>
              <input
                class="form-control"
                type="type"
                v-model="name"
                placeholder="請輸入角色名稱"
              />
            </div>
          </div>

          <div class="col-sm-3 col-md-3">
            <button
              type="button"
              class="btn btn-primary btn-search"
              style="margin-top: 30px; margin-left: 10px"
              @click="getRole"
            >
              查詢
            </button>
            <button
              type="button"
              style="margin-top: 30px; margin-left: 10px"
              class="btn btn-primary btn-create"
              data-toggle="modal"
              data-target="#roleModal"
              @click="openModal(true)"
            >
              新增
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="yahome-table-wrap">
      <vue-bootstrap4-table :rows="rows" :columns="columns" :config="config">
        <template slot="setMenu" slot-scope="props">
          <button
            class="btn btn-primary btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#joinauthority"
            @click="openSetMenuModal(props.row)"
          >
            設定選單功能
          </button>
        </template>
        <template slot="setUser" slot-scope="props">
          <button
            class="btn btn-info btn-edit btn-sm"
            style="margin-right: 0.5rem"
            @click="openSetUserModal(props.row)"
          >
            設定使用者
          </button>
        </template>

        <template slot="edit" slot-scope="props">
          <button
            class="btn btn-info btn-edit btn-sm"
            style="margin-right: 0.5rem"
            data-toggle="modal"
            data-target="#roleModal"
            @click="openModal(false, props.row)"
          >
            編輯
          </button>
        </template>

        <template slot="del" slot-scope="props">
          <button
            class="btn btn-danger btn-delete btn-sm"
            data-toggle="modal"
            data-target="#delRoleModal"
            @click="openModal(false, props.row)"
          >
            刪除
          </button>
        </template>
      </vue-bootstrap4-table>
    </div>

    <!-- 新增&編輯角色 Modal -->
    <div
      id="roleModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="role"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="isAdd">新增角色</span>
              <span v-else>編輯角色</span>
              <!-- <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <button class="btn" type="reset" @click="close">
                  <span style="color: #222">&times;</span>
                </button>
              </button> -->
            </div>
            <div class="modal-body">
              <div class="field">
                <ValidationProvider
                  name="角色名稱"
                  :rules="`my_not:${nowItem.Name}|required`"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>角色名稱
                    <span class="mark">(名稱不可重複)</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="請輸入角色名稱"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="item.Name"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                aria-label="關閉"
                data-dismiss="modal"
              >
                取消
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(saveRole)"
              >
                儲存
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- 刪除角色 Modal -->
    <div
      id="delRoleModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">刪除角色</div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ item.Name }} </span
            >?
          </div>
          <div class="modal-footer">
            <button
              type="reset"
              class="btn btn-secondary"
              aria-label="關閉"
              data-dismiss="modal"
            >
              取消
            </button>
            <button type="button" class="btn btn-danger" @click="delRole">
              刪除
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 設定選單跟功能 -->
    <div
      id="setMenuModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form @submit.prevent="savePermissions">
            <div class="modal-header">
              <span>{{ currentRole.Name }} -選單功能</span>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="menu-select-list">
                    <div
                      v-for="(parentMenu, index) in menuList"
                      :key="index"
                      :class="{ open: currentMeunGuid === parentMenu.Guid }"
                    >
                      <div
                        class="menu-name"
                        @click="currentMeunGuid = parentMenu.Guid"
                      >
                        <span>
                          {{ parentMenu.MenuName }}
                        </span>
                        <eva-icon
                          fill="#000"
                          name="chevron-right-outline"
                        ></eva-icon>
                      </div>
                      <div class="option-wrap">
                        <div
                          v-for="(childMenu, index) in parentMenu.Children"
                          class="option"
                          :class="{
                            active: currentOptionGuid === childMenu.Guid
                          }"
                          :key="index"
                          @click="getPermisson(childMenu)"
                        >
                          {{ childMenu.MenuName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="permission-select-list">
                    <label
                      v-for="(permission, index) in permissionList"
                      :key="index"
                    >
                      <input v-model="permission.IsSelected" type="checkbox" />
                      <span>{{ permission.PermissionName }}</span>
                      <div class="switch">
                        <span></span>
                      </div>
                    </label>
                    <div v-if="permissionList.length <= 0">尚無功能..</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                取消
              </button>
              <button
                type="button"
                @click="saveRoleMenu()"
                class="btn btn-primary"
              >
                儲存
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--設定人員Modal-->
    <div
      id="setUserModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <span>{{ currentRole.Name }} -設定人員</span>
          </div>
          <div class="modal-body">
            <el-transfer
              :titles="['未選取人員', '已選取人員']"
              filterable
              filter-placeholder="請輸入姓名或帳號"
              :filter-method="filterMethod"
              :data="userList"
              v-model="userListSelection"
            ></el-transfer>
          </div>

          <div class="modal-footer">
            <button
              type="reset"
              class="btn btn-secondary"
              aria-label="關閉"
              data-dismiss="modal"
            >
              取消
            </button>
            <button
              type="button"
              @click="setRoleUser()"
              class="btn btn-primary"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import VueBootstrap4Table from "vue-bootstrap4-table";
import Alert from "@/components/back/AlertMessage.vue";

export default {
  name: "Role",
  data() {
    return {
      is_composing: false, // compositionstart& compositionend

      isAdd: false,
      item: {},
      nowItem: {},

      name: "", //查詢角色

      //選中的選單參數
      menuGuid: "",
      Permissions: [],
      allPermissions: [],
      //左側的人
      userList: [],
      //右側的人
      userListSelection: [],

      currentRole: "",

      personList: [
        {
          label: "系統管理",
          key: "001",
          pinyin: "系統管理-001",
        },
        {
          label: "基礎資料管理",
          key: "002",
          pinyin: "基礎資料管理-002",
        },
        {
          label: "人才庫",
          key: "003",
          pinyin: "人才庫-003",
        },
        {
          label: "信件範本維護",
          key: "004",
          pinyin: "信件範本維護-004",
        },
      ], //??人員

      value: [], // filter人員用

      // ajaxOptions: {
      //   url: `${process.env.VUE_APP_API}/auth/permission`,
      //   dataType: "json",
      //   delay: 250,
      //   tags: true,
      //   data: function (params) {
      //     return {
      //       term: params.term, // search term
      //       page: params.page,
      //     };
      //   },
      //   processResults: function (data, params) {
      //     params.page = params.page || 1;
      //     return {
      //       results: data,
      //       pagination: {
      //         more: params.page * 30 < data.total_count,
      //       },
      //     };
      //   },
      //   cache: true,
      // },
      rows: [],
      columns: [
        {
          label: "角色名稱",
          name: "Name",
          sort: true,
        },
        {
          label: "選單功能",
          name: "setMenu",
          slot_name: "setMenu",
        },

        {
          label: "使用者",
          name: "setUser",
          slot_name: "setUser",
        },
        {
          label: "",
          name: "edit",
          slot_name: "edit",
        },
        {
          label: "",
          name: "del",
          slot_name: "del",
        },
      ],
      config: {
        global_search: {
          visibility: false,
        },

        show_refresh_button: false,
        show_reset_button: false,

        pagination: true,
        pagination_info: false,
        num_of_visibile_pagination_buttons: 5,
        per_page: 20,
        per_page_options: [5, 10, 20, 30],
        selected_rows_info: false,

        highlight_row_hover: false,
        rows_selectable: true,
        multi_column_sort: false,
        card_mode: false,
      },
      menuList: [],
      permissionList: [],
      currentMeunGuid: "", //目前展開的選單
      currentOptionGuid: "", //目前選中的選單項目
      selectPermissionGuidList: [], //有勾選功能的GUID
    };
  },

  components: {
    Alert,
    VueBootstrap4Table,
  },

  methods: {
    // 取得角色 API
    getRole() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/role`;
      const params = {
        name: vm.name,
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http.get(api, { params }).then((response) => {
        if (response.status === 200) {
          
          vm.$store.dispatch("updateLoading", false);
          vm.rows = response.data;
        }
      });
    },

    //取得選單
    // getMenu() {
    //   const vm = this;
    //   const api = `${process.env.VUE_APP_API}/auth/menu`;
    //   vm.$store.dispatch("updateLoading", true);
    //   vm.$http
    //     .get(api)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         console.log(response.data);
    //         vm.$store.dispatch("updateLoading", false);
    //         vm.menuList = response.data;
    //         console.log("menuList", vm.menuList);
    //       }
    //     })
    //     .catch((error) => {
    //       vm.$store.dispatch("updateLoading", false);
    //       vm.$bus.$emit("message: push", error.response.data, "danger");
    //     });
    // },
    //取得功能
    // getPermisson(Guid) {
    //   const vm = this;
    //   vm.currentOptionGuid = Guid;
    //   const api = `${process.env.VUE_APP_API}/auth/permission`;
    //   // vm.$store.dispatch("updateLoading", true);
    //   const params = {
    //     menuGuid: Guid,
    //   };
    //   vm.$http
    //     .get(api, { params })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         console.log(response.data);
    //         // vm.$store.dispatch("updateLoading", false);
    //         vm.permissionList = response.data;
    //       }
    //     })
    //     .catch((error) => {
    //       vm.$store.dispatch("updateLoading", false);
    //       vm.$bus.$emit("message: push", error.response.data, "danger");
    //     });
    // },
    //取得選單功能改API改成一隻
    getMenu() {
      const vm = this;
      const rolename = vm.currentRole.Name;
      const api = `${process.env.VUE_APP_API}/auth/rolemenu/${rolename}`;
      vm.selectPermissionGuidList = [];
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            
            vm.$store.dispatch("updateLoading", false);
            vm.menuList = response.data;
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    getPermisson(childMenu) {
      const vm = this;
      vm.permissionList = childMenu.Permissions;
      vm.currentOptionGuid = childMenu.Guid;
    },
    //儲存選單腳色功能
    saveRoleMenu() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/rolemenu`;
      vm.GetPermissionsGuid();
 
      const data = {
        RoleGuid: vm.currentRole.Id,
        PermissionGuid: vm.selectPermissionGuidList,
      };
 
      $("#setMenuModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "編輯成功");
            vm.selectPermissionGuidList = [];
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    //取得所有成員(左側)
    getAllUser() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/backgrounduser`;
      // vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.userList = [];
            response.data.forEach((item) => {
              vm.userList.push({
                label: item.Name,
                key: item.Account,
                filterData:
                  item.Name +
                  "-" +
                  item.Account +
                  "-" +
                  item.Email +
                  "-" +
                  item.PhoneNumber,
              });
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    //transfer filter(左右側共用)
    filterMethod(query, item) {
      return item.filterData.indexOf(query) > -1;
    },

    //取得角色成員(右側)
    getRoleUser(RoleName) {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/rolemember/${RoleName}`;
      // vm.$store.dispatch("updateLoading", true);

      vm.userListSelection = [];
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            
            //vm.$store.dispatch("updateLoading", false);
            response.data.forEach((item) => {
              vm.userListSelection.push(item.Account);
            });

            // console.log("userListSelection",vm.userListSelection);
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    //設定角色成員(儲存左右側置換結果)
    setRoleUser() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/rolemember`;
      const data = {
        RoleName: vm.currentRole.Name,
        Account: vm.userListSelection,
      };

      
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            $("#setUserModal").modal("hide");
            vm.$bus.$emit("message: push", "儲存成功");
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    openSetUserModal(row) {
      const vm = this;
      vm.currentRole = row;
      vm.getAllUser();
      vm.getRoleUser(row.Name);
      $("#setUserModal").modal("show");
    },

    openSetMenuModal(row) {
      const vm = this;
      vm.currentRole = row;
      vm.menuList = [];
      vm.permissionList = [];
      vm.currentOptionGuid = "";
      vm.currentMeunGuid = "";
      vm.getMenu();
      $("#setMenuModal").modal("show");
    },

    // 新增&編輯 角色 Modal
    openModal(isAdd, item) {
      if (!isAdd) {
        this.item = { ...item };
        this.nowItem = {
          // 原角色 data
          ...item,
        };
        this.isAdd = false;
      } else {
        this.item = {};
        this.isAdd = true;
      }
      this.$refs.role.reset();
    },

    // 新增&編輯 角色 API
    saveRole() {
      const vm = this;
      let api = `${process.env.VUE_APP_API}/auth/role`;
      let httpMethod = "post";
      let message = "新增成功";

      if (!vm.isAdd) {
        api = `${process.env.VUE_APP_API}/auth/role/${vm.item.Id}`;
        httpMethod = "put";
        message = "編輯成功";
      }


      $("#roleModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http[httpMethod](api, JSON.stringify(vm.item.Name))
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", message);
            vm.getRole();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 權限 Modal
    openPermissions() {
      const vm = this;
      vm.authority = [];
    },

    // 刪除角色 API
    delRole() {
      const vm = this;
      const api = `${process.env.VUE_APP_API}/auth/role/${vm.item.Id}`;

      $("#delRoleModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .delete(api)
        .then((res) => {
          if (res.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$bus.$emit("message: push", "刪除成功");
            vm.getRole();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },
    //取得有勾選的功能
    GetPermissionsGuid() {
      const vm = this;
      return vm.menuList.forEach((item) => {
        item.Children.forEach((itemm) => {
          itemm.Permissions.forEach((itemmm) => {
            if (itemmm.IsSelected === true) {
              vm.selectPermissionGuidList.push(itemmm.PermissionGuid);
            }
          });
        });
      });
    },
  },

  computed: {
    // ts() {
    //   const vm = this;
    //   return vm.menuList.forEach((item) => {
    //     item.children.forEach((itemm) => {
    //       itemm.Permissions.forEach((itemmm) => {
    //         itemmm.IsSelected.filter((isTrue) => {
    //           return isTrue;
    //         });
    //       });
    //     });
    //   });
    // },
    PermissionsName() {
      const vm = this;
      return vm.allPermissions.map((item) => {
        return item.Name;
      });
    },

    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  watch: {
    // ts(n) {
    //   console.log(n);
    // },
    // Permissions(n) {
    //   console.log(n);
    // },
    // GetPermissionsGuid(n) {
    //   console.log("GetPermissionsGuid", n);
    // },
    // Permissions.find(item => {
    //         if(item.IsSelected) {
    //             Chilren[index] = {
    //                 ...Chilren,
    //                 select: true
    //             }
    //         }
    //     })
    // permissionList: {
    //   detectPermissionCheck(childMenu) {
    //     childMenu.Permissions.filter(function (ele) {
    //       return ele.IsSelected == true;
    //     });
    //   },
    // },
  },

  created() {
    this.getRole();
  },
};
</script>
<style lang="scss" scoped>
.menu-select-list {
  overflow: auto;
  height: 100%;
  & > div {
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-bottom: 5px;

    .menu-name {
      padding: 10px;
      cursor: pointer;
      opacity: 0.5;
      display: flex;
      span {
        flex: 1;
      }
      i {
        transition: all 0.2s;
      }
    }
    .option-wrap {
      max-height: 0px;
      overflow: hidden;
      padding: 0 10px;
      transition: all 0.2s;

      .option {
        background-color: #fff;
        text-align: center;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 5px 0;
        display: block;
        cursor: pointer;
        font-size: 0.9em;
        transition: all 0.2s;

        &.active {
          background-color: #5380f7;
          color: #fff;
        }
        &.hascheck {
          &:before {
            content: "✔";
          }
        }
      }
    }

    &.open {
      .menu-name {
        i {
          transform: rotate(90deg);
        }
      }
      .option-wrap {
        max-height: 1000px;
      }
    }
  }
}
.permission-select-list {
  label {
    display: block;
    background-color: #f1f1f1;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    cursor: pointer;

    input[type="checkbox"] {
      display: none;
      &:checked {
        & ~ .switch {
          span {
            background-color: #5380f7;
            transform: translateX(calc(100% - 6px));
          }
        }
      }
    }
    & > span {
      flex: 1;
    }

    .switch {
      display: inline-block;
      vertical-align: middle;
      padding: 3px;
      border: 3px solid #c5c5c5;
      background-color: #fff;
      border-radius: 25px;
      width: 60px;
      position: relative;
      height: 29px;
      span {
        width: 50%;
        height: calc(100% - 6px);
        position: absolute;
        left: 3px;
        top: 3px;
        background-color: #c5c5c5;
        border-radius: 25px;
        transition: all 0.3s;
      }
    }
  }
}
</style>